import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'
import styled from '@emotion/styled'


const Text = styled.p`
  text-align: center;
  line-height: 1.6;
  a {
    color: ${props => props.theme.colors.text};
  }
`


const Contact = ({ data }) => {
  return (
    <Layout>
      <SEO title="Insight Centric Contact" description="Contact Form for Insight Centric" />
      <Container>
        <PageTitle>Contact Page</PageTitle>
        <Text>
        We love to hear from our readers. Please reach out with questions.
      </Text>
      <br></br>
        <ContactForm />
      </Container>
        
    </Layout>
  )
}

export default Contact
